import { computed, ref, watch } from '@vue/composition-api'
import http from '@/global/http'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import { getBeforeMonth } from '@/utils/helpers'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useReportsOverview() {
  const toast = useToast()
  const reportGeneralTitle = ref('')
  const creatorId = ref('')
  const departmentId = ref('')
  const myVoteLists = ref([])
  const myVoteBeforeMonth = computed(() => {
    const { month, year } = getBeforeMonth()
    return myVoteLists.value.find(item => item.month === month && item.year === year)
  })
  const selectVoteUserId = ref('')
  const currentDepartment = ref({})
  const currentDepartmentNotes = ref([])
  const popupTeamNoteData = ref('')
  const popupTeamReview = ref('')
  const currentDetailDepartmentReview = ref({})
  const currentDetailDepartmentReviewMode = ref({})
  const teamLeader = ref({})
  const teamLeaderReview = ref([])
  const reportTeamType = ref('')
  const currentDepartmentName = ref('')
  const userId = ref('')
  const reportMePkhData = ref({})
  const reportMeData = ref({})
  const currentModalDebtDetailData = ref({})
  const debtCollectionReportList = ref([])
  const userListActivity = ref([])

  //------------

  const teamDetailReport = ref({})
  const chartData = ref({})
  const userMeData = ref({})
  const userData = ref({})
  const sumData = ref({})
  const hashMemberNameById = computed(() => {
    const members = sumData.value?.sum_transactions || []
    return members.reduce((acc, item) => {
      acc[item.user_id] = item.user_name
      return acc
    }, {})
  })
  const TDPCIds = computed(() => sumData.value?.sum_transactions_all?.filter(item => item.job_title_code === 'TDPC').map(item => item.user_id))
  const votedByTeam = ref({})
  const allDepartmentVote = ref([])
  const allDepartmentVoteWithUserNames = computed(() => allDepartmentVote.value.map(item => {
    return {
      ...item,
      voter_name: hashMemberNameById.value[item.voter_id],
      voted_user_name: hashMemberNameById.value[item.voted_user_id],
    }
  }))
  const membersInTeam = computed(() => (sumData.value?.sum_transactions || []).map(user => {
    if (ability().can('view', 'Vote')) {
      return {
        ...user,
        vote_count: votedByTeam.value[user.user_id] || 0,
      }
    }
    return user
  }))
  const userRating = ref({})
  const departmentReportPlan = ref([])
  const userSumData = ref({})
  const reportMode = ref('')
  const isPkh = computed(() => getUserData()
    ?.department
    ?.code
    ?.toLowerCase() === 'pkh')

  const reportOverviewData = ref({})
  const teams = ref([])
  const departments = ref([])
  const refs = ref([])
  const monthDateFilter = ref('')

  const m = new Date().getMonth() + 1
  const y = new Date().getFullYear()
  const monthFilter = ref(m > 9 ? m : `0${m}`)
  const yearFilter = ref(y)

  const getSalaryTeam = (transactions = []) => {
    let amountTeamLeader = 0
    let amountTeamMember = 0

    transactions.forEach(item => {
      if (item.job_title_code === 'TDPC') {
        amountTeamLeader = item.total || 0
      }
      if (item.job_title_code === 'DPC') {
        amountTeamMember = amountTeamMember || item.total
      }
    })

    return {
      amountTeamLeader,
      amountTeamMember,
    }
  }

  const getDateRange = () => {
    reportGeneralTitle.value = !yearFilter.value ? 'Tất cả' : `${monthFilter.value ? `Tháng ${monthFilter.value}` : ''} Năm ${yearFilter.value}`

    if (!yearFilter.value) {
      return {
        from: '',
        to: '',
      }
    }

    return {
      from: !monthFilter.value ? new Date(yearFilter.value, 0, 1)
        : new Date(yearFilter.value, parseFloat(monthFilter.value - 1), 1),
      to: !monthFilter.value ? new Date(yearFilter.value, 12, 0, 23, 59, 59) : new Date(yearFilter.value, monthFilter.value, 0, 23, 59, 59),
    }
  }

  const getMonthYearFilter = () => ({
    month: monthFilter.value,
    year: yearFilter.value,
  })

  const getQuery = filter => {
    const {
      from,
      to,
    } = getDateRange()

    return http.buildQuery({
      from: http.getISODate(filter.from || from),
      to: http.getISODate(filter.to || to),
      department_id: filter.departmentId || departmentId.value,
      creator_id: filter.creatorId || creatorId.value,
      user_id: filter.user_id || userId.value,
    }) || ''
  }

  const fetchSum = (filter = {}) => {
    const queryString = getQuery(filter)

    try {
      http.api(store.dispatch('report/sum', queryString), res => {
        sumData.value = res.data || {}
        teams.value = res.data.report_deparment || []
        departments.value = res.data.report_user || []
        refs.value = res.data.report_know_with || []
        refs.value.sort((a, b) => (a.info_how_to_know_me.toUpperCase() < b.info_how_to_know_me.toUpperCase() ? -1 : 1))
      })
    } catch (e) {
      console.log(e)
    }
  }

  const fetchDepartmentVote = () => {
    const {
      month,
      year,
    } = getMonthYearFilter()
    const query = http.buildQuery({
      month,
      year,
    })
    http.api(store.dispatch('report/fetchDepartmentVote', { id: departmentId.value, query }), res => {
      votedByTeam.value = res.data.reduce((acc, item) => {
        acc[item.user_id] = item.vote_count
        return acc
      }, {})
    })
  }

  const fetchAllDepartmentVote = (filter = {}) => {
    const {
      month,
      year,
    } = getMonthYearFilter()

    const query = http.buildQuery({
      month,
      year,
      department_id: filter.departmentId || departmentId.value,
    }) || ''
    http.api(store.dispatch('report/fetchAllDepartmentVote', query), res => {
      allDepartmentVote.value = res.data || []
    })
  }

  const fetchReportRating = (filter = {}) => {
    const queryString = getQuery(filter)

    return http.api(store.dispatch('report/rating', queryString), res => {
      departmentReportPlan.value = res.data || []
    })
  }

  const fetchSumMe = (filter = {}) => {
    const queryString = getQuery(filter)

    return http.api(store.dispatch('report/sumMe', queryString), res => {
      sumData.value = res.data || {}
      teams.value = res.data.report_deparment || []
      departments.value = res.data.report_user || []
      refs.value = res.data.report_know_with || []
      refs.value.sort((a, b) => (a.info_how_to_know_me.toUpperCase() < b.info_how_to_know_me.toUpperCase() ? -1 : 1))
    })
  }

  const updateRatingTDPC = data => {
    const ndata = {
      department_id: 16,
      work_plan_users: [
        {
          user_id: 97,
          id: 97,
          rating_point: 5,
          note: 'test',
        },
      ],
    }
    http.api(store.dispatch('report/updateRatingTDPC', ndata), res => {
    })
  }

  const fetchCurrentDepartmentNotes = id => {
    const {
      from,
      to,
    } = getDateRange()

    http.api(store.dispatch('department/fetchDepartmentNotes',
      http.buildQuery({
        department_id: id || departmentId.value,
        from: http.getISODate(from),
        to: http.getISODate(to),
      })), res => {
      currentDepartmentNotes.value = (res.data || []).reverse()
      return currentDepartmentNotes.value
    })
  }

  const fetchDebtCollectionDetailReport = id => {
    const {
      from,
      to,
    } = getDateRange()

    http.api(store.dispatch('department/fetchDebtCollectionReport',
      http.buildQuery({
        type: '',
        from: http.getISODate(from),
        to: http.getISODate(to),
        department_id: id || departmentId.value,
      })), res => {
      debtCollectionReportList.value = (res.data || []).reverse()
      return debtCollectionReportList.value
    })
  }

  const fetchMyVote = () => http.api(store.dispatch('department/fetchMyVote'), res => {
    myVoteLists.value = res.data || []
    return myVoteLists.value
  })

  const voteBestUserMonth = (data, successCb = () => {}) => http.api(store.dispatch('department/voteBestUserMonth', data)).then(() => {
    toast({
      component: ToastificationContent,
      props: { title: 'Bình chọn thành công', variant: 'success' },
    })
    successCb()
  }).catch(() => {
    toast({
      component: ToastificationContent,
      props: { title: 'Bình chọn thất bại', variant: 'danger' },
    })
  })

  const removeDepartmentReview = dReview => http.api(store.dispatch('department/deleteDepartmentReview', dReview), () => {
    const idx = teamLeaderReview.value.findIndex(u => u.id === dReview.id)
    teamLeaderReview.value.splice(idx, 1)
    currentDetailDepartmentReview.value = {}
    currentDetailDepartmentReviewMode.value = 'create'
  })

  const handleCreateTeamNote = data => {
    const ndata = {
      content: data,
      note_at: new Date(yearFilter.value, monthFilter.value - 1),
      department_id: departmentId.value ? parseFloat(departmentId.value) : '',
    }
    http.api(store.dispatch('department/createDepartmentNote', ndata), res => {
      currentDepartmentNotes.value.unshift(res.data)
      fetchCurrentDepartmentNotes()
    })
  }

  const fetchUserActivities = (filter = {}) => {
    const queryString = http.buildQuery({
      user_id: userId.value,
      ...filter,
    }) || ''
    return http.api(store.dispatch('department/fetchUserActivities', queryString), res => {
      userListActivity.value = res.data || []
    })
  }

  const handleDeleteNote = id => {
    http.api(store.dispatch('department/deleteDepartmentNote', { id }), res => {
      fetchCurrentDepartmentNotes()
      const idx = currentDepartmentNotes.value.findIndex(item => item.id === id)
      currentDepartmentNotes.value.splice(idx, 1)
    })
  }

  const fetchCurrentDepartment = id => {
    if (id === departmentId.value || !departmentId.value) return

    http.api(store.dispatch('department/fetchDepartment',
      { id: id || departmentId.value }), res => {
      currentDepartment.value = res.data
      return currentDepartment.value
    })
  }

  const fetchCurrentDepartmentTeamLeaderReview = (filter = {}) => {
    const {
      from,
      to,
    } = getDateRange()

    const queryString = http.buildQuery({
      department_id: departmentId.value,
      from: http.getISODate(filter.from || from),
      to: http.getISODate(filter.to || to),
    })

    http.api(store.dispatch('report/fetchDepartmentReview', queryString))
      .then(res => {
        teamLeaderReview.value = res.data
      })
  }

  const createDepartmentReview = (data = {}) => {
    // eslint-disable-next-line no-param-reassign
    data.rated_at = new Date(yearFilter.value, monthFilter.value - 1)
    // eslint-disable-next-line no-param-reassign
    data.department_id = Math.floor(data.department_id || departmentId.value)
    http.api(store.dispatch('department/createDepartmentReview', data), () => {
      fetchCurrentDepartmentTeamLeaderReview()
    })
  }

  const updateDepartmentReview = (data = {}) => {
    // eslint-disable-next-line no-param-reassign
    data.department_id = Math.floor(data.department_id || departmentId.value)
    http.api(store.dispatch('department/updateDepartmentReview', data), () => {
      fetchCurrentDepartmentTeamLeaderReview()
    })
  }

  const exportTeamReportMember = (filter = {}) => {
    const {
      from,
      to,
    } = getDateRange()

    const queryString = http.buildQuery({
      department_id: departmentId.value,
      from: http.getISODate(filter.from || from),
      to: http.getISODate(filter.to || to),
    })

    return http.api(store.dispatch('report/exportTeamReportMember', {
      q: queryString,
      department_id: departmentId.value,
    }))
  }

  const fetchUserRating = (userRatingFilter = {}) => {
    const {
      from,
      to,
    } = getDateRange()

    http.api(store.dispatch('report/rating', http.buildQuery({
      from: http.getISODate(userRatingFilter.from || from),
      to: http.getISODate(userRatingFilter.to || to),
      ...userRatingFilter,
    })), _res => {
      userRating.value = Array.isArray(_res.data) ? _res.data.find(item => item.user_id.toString() === userRatingFilter.user_id?.toString()) : _res.data
    })
  }

  const fetchUserSum = (filter = {}) => {
    reportGeneralTitle.value = !yearFilter.value ? 'Tất cả' : `${monthFilter.value ? `Tháng ${monthFilter.value}` : ''} Năm ${yearFilter.value}`

    const queryString = getQuery(filter)

    const uId = filter.user_id || userId.value

    const type = uId ? 'user' : 'me'

    if (type === 'user') {
      return http.api(store.dispatch('report/user', {
        id: uId,
        q: queryString,
      }), res => {
        userSumData.value = res.data || {}
      })
        .then(res => {
          fetchUserRating({
            department_id: res.data?.user?.department_id,
            user_id: uId,
          })
        })
    }

    if (isPkh.value) {
      return http.api(store.dispatch('report/creator', queryString), res => {
        sumData.value = res.data || {}
      })
    }
    const account = getUserData()

    return http.api(store.dispatch('report/me', queryString), res => {
      userSumData.value = res.data || {}
    })
      .then(res => {
        fetchUserRating({
          department_id: res.data?.user?.department_id,
          user_id: account.id,
        })
      })
  }

  watch([departmentId], () => {
    fetchCurrentDepartment()
  })
  watch([currentDepartment], () => {
    const members = currentDepartment.value.users || []
    teamLeader.value = members.find(item => item.job_title_code?.toLowerCase() === 'tdpc') || {}
  })

  watch([reportMode, yearFilter, monthFilter, departmentId, userId, creatorId], () => {
    fetchCurrentDepartmentNotes()
    fetchCurrentDepartmentTeamLeaderReview()

    switch (reportMode.value) {
      case 'reports':
      case 'reports-team':
      case 'reports-user-pkh':
        fetchSum()
        fetchReportRating()
        if (ability().can('view', 'Vote')) {
          fetchDepartmentVote()
          fetchAllDepartmentVote()
        }
        return
      case 'reports-me':
        fetchUserSum()
        return
      case 'reports-user':
        fetchUserSum()
        fetchUserActivities()
        return
      case 'reports-my-team':
        fetchSumMe()
        fetchReportRating()
        return
      default:
        fetchUserSum()
    }
  })

  return {
    reportMeData,
    reportMePkhData,
    reportGeneralTitle,
    currentDepartmentName,
    reportTeamType,
    creatorId,
    departmentId,
    reportMode,
    userId,
    yearFilter,
    isPkh,
    monthFilter,
    reportOverviewData,
    teams,
    departments,
    refs,
    monthDateFilter,
    teamDetailReport,
    chartData,
    userMeData,
    userSumData,
    userData,
    teamLeaderReview,
    sumData,
    membersInTeam,
    departmentReportPlan,
    teamLeader,
    userRating,
    currentDepartmentNotes,
    popupTeamNoteData,
    popupTeamReview,
    currentDetailDepartmentReview,
    currentDetailDepartmentReviewMode,
    currentModalDebtDetailData,
    debtCollectionReportList,
    userListActivity,
    myVoteLists,
    myVoteBeforeMonth,
    selectVoteUserId,
    voteBestUserMonth,
    allDepartmentVoteWithUserNames,
    fetchDepartmentVote,
    fetchAllDepartmentVote,
    removeDepartmentReview,
    getSalaryTeam,
    fetchSum,
    fetchUserSum,
    fetchReportRating,
    fetchSumMe,
    getDateRange,
    updateRatingTDPC,
    handleCreateTeamNote,
    handleDeleteNote,
    createDepartmentReview,
    updateDepartmentReview,
    exportTeamReportMember,
    fetchCurrentDepartment,
    fetchCurrentDepartmentNotes,
    fetchDebtCollectionDetailReport,
    fetchUserActivities,
    fetchMyVote,
    hashMemberNameById,
    TDPCIds,
  }
}
